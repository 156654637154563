import Modal from 'react-bootstrap/Modal';
import { validate } from 'react-email-validator';
import React, { useState } from "react"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function EmailModal(props) {
  
  const [emailValue,setEmailValue] = useState()
  const [name,setName] = useState()
  
  const [emailValid,setEmailValid] = useState(true)
  const [userTypedEmail,setUserTypedEmail] = useState(false)
  const postUrl ="https://api.primestaterealty.omarwasfi.com/ClientRequest/AddClientRequest"

  
  const required = (value) => {
    if (!value.toString().trim().length) {
      // We can return string or jsx as the 'error' prop for the validated Component
      return 'require';
    }
  };
  const email = (value) => {
    //console.log("hi")
    if (!validate(value)) {
      return `${value} is not a valid email.`
    }
    };    
   function checkEmail (e) {
    setEmailValue(e.target.value)
    if (!validate(emailValue)) {
      setEmailValid(false)    
    }else{
      setEmailValid(true)
    }
   }
   function resetEmailValid(){
    setEmailValid(true)
    setUserTypedEmail(false)
  }
  function handleEmailLeave(e){
    setUserTypedEmail(true)
    checkEmail(e)
  }
  
  const notify = () => toast.success('Success', {
    className: 'toast-message'
  });
  const notValidNotify = () => toast.error('Not a valid email', {
    className: ''
  });
  function Handlesubmit (){
    let data = {
      name: name,
      email: emailValue,
      phoneNumber: "",
      message: "",
      comingFromProperty: true,
      savedTheProperty: true,
      propertyId: props.propertyId
    }
    //console.log(data)
    if (validate(emailValue)){    
      notify()
      props.onHide()
      //console.log("submit")
      fetch(postUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json-patch+json',
          'accept': 'text/plain'
        },
        body: JSON.stringify(data)
      })
        //.then(response => response.json())
        .then(data => {
          //console.log('Success:', data);
        })
        .catch((error) => {
          //console.error('Error:', error);
        });
    }else{
      setEmailValid(false)
      notValidNotify()
    }
  }
   return (
    <>

      <Modal
        {...props}
        size="m"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onExit={resetEmailValid}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Save Property</Modal.Title>
        </Modal.Header>
        <Modal.Body>
       
       <div className='property-page property-sidebar'>
                <form action="#" >
                    <ul className="sasw_list mb0">
                      <li className="search_area"><div className="form-group mb-3" onChange={(e)=>setName(e.target.value)}><input type="text" className="form-control" id="form-name" placeholder="Your Name" required /></div></li>
                      
                      <li className="search_area"><div className="form-group " onchange={checkEmail} onInput={handleEmailLeave} onTouchCancel={handleEmailLeave}><input type="email" className="form-control" placeholder="Email" required /></div></li> 
                      {!emailValid && userTypedEmail ? <span style={{color:"#d62b1f",margin:"0px",padding:"0px"}}>* Not a valid email</span>:<></>}
                    </ul>
                </form>
        </div>            
                  
        </Modal.Body>
        <Modal.Footer>
          <button onClick={Handlesubmit} type="submit"  className="green-btn">Send</button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
      </>
    );
  }