import Carousel from 'react-bootstrap/Carousel';
import {useState ,useEffect} from 'react';

const url = "https://api.primestaterealty.omarwasfi.com/"


function CarouselFadeExample(props) {
  const [controls, setControls] = useState(false);

  useEffect(()=>{
  if (window.innerWidth<=768){
    setControls(true)
  }
  }, [])

    return (
      <Carousel fade interval={null}  className="property-carousel" 
      onMouseEnter={function (){setControls(true)}}
      onMouseLeave={function (){setControls(false)}}
      controls={controls}
      indicators={true}
      >
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={url+props.thumbnail.FullPath400px}
                alt="First slide"
              />
    
            </Carousel.Item>
        {
          props.pictures.map(function(picture){
            return(
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={url+picture.FullPath400px}
                alt="First slide"
              />
    
            </Carousel.Item>
            )
          })
        }


      </Carousel>
    );

}

export default CarouselFadeExample;


