import React from "react"
import './propertiesCard.css'
import Card from "./propertyCard"
import {HashLink as Link} from 'react-router-hash-link';
import { useQuery } from 'react-query';

const url = "https://api.primestaterealty.omarwasfi.com/Property/GetProperties?publishedOnly=true&countAView=true"

function Properties (props) {

      const { isLoading, error, data } = useQuery('data', ()=> {
        return fetch(url).then((response) => response.json())
      }
      );
      const items = data
      if (!isLoading) {

        if(props.number) {
            let cards = [] ;
            for (let i = 0; i < props.number; i++) {
                cards[i] =
                <Card
                id={items[i].Id}
                thumbnail={items[i].Thumbnail}
                pictures={items[i].Pictures}
                location={items[i].Location}
                type={items[i].PropertyTypeName}
                subType={items[i].PropertySubTypeName}
                area={items[i].AreaSqFt}
                price={items[i].Price}
                priceAvaiable={items[i].PriceAvaiable}
                date={items[i].PublishedOnDateTime}
            />;
                
              }
              return(
                <section className="Properties-section" id="Properties">
                <div className="Properties">
                    {cards}
                </div>
                <div class="text-center"style={{marginTop:"20px"}}><Link to="/properties#"><button className="green-btn" >Show More</button></Link></div>
                </section>
                )
        }
        return(
            <section className="Properties-section" id="Properties">
            <div className="Properties">
                {items.map(function (item){
                    //console.log (item)
                    return(
                        <Card 
                            id={item.Id}
                            thumbnail={item.Thumbnail}
                            pictures={item.Pictures}
                            location={item.Location}
                            type={item.PropertyTypeName}
                            subType={item.PropertySubTypeName}
                            area={item.AreaSqFt}
                            price={item.Price}
                            priceAvaiable={item.PriceAvaiable}
                            date={item.PublishedOnDateTime}

                        />
                    );
                })}
            </div>
            </section>

        );
    }
    else{
        return <div style={{height:"80vh",display:"flex",justifyContent:"center",alignItems:"center"}}><div class="loader"></div></div>
    }
    }

export default Properties;
