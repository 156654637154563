import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css'

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query';

import HomePage from './pages/HomePage';
import PropertiesPage from './pages/properties';
import Property from './pages/Property';
import Unsubscribe from './pages/Unsubscribe';

const queryClient = new QueryClient();

function App() {
  return (

  <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route exact path="/" element={<HomePage/>} />
          <Route exact path="/properties" element={<PropertiesPage/>} />
          <Route exact path="/view/:id" element={<Property />} />
          <Route exact path="/unsubscribe/:id" element={<Unsubscribe/>} />
        </Routes>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
