import React, { useState } from "react"
import "./unsubscribe.css"
import NavBar from "../../components/navbar/index"
import Footer from "../../components/footer/index"
import {Helmet} from "react-helmet"
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


export default function Unsubscribe(){
    const navigate = useNavigate();
    const [message,setMessage] = useState()
    const postUrl = "https://api.primestaterealty.omarwasfi.com/UnsubscribeRequest/AddUnsubscribeRequest"
    const userId =useParams().id;
    const notify = () => toast('Unsubscribed', {
        className: 'toast-message'
      });

    function Handlesubmit (e){
        e.preventDefault()
        notify()
        let data = {
            clientId :userId,
            message: message
        }
       //console.log(data)
          //console.log("submit")
          fetch(postUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json-patch+json',
              'accept': 'text/plain'
            },
            body: JSON.stringify(data)
          })
            //.then(response => response.json())
            .then(data => {
              //console.log('Success:', data);
              navigate("/")
            })
            .catch((error) => {
              //console.error('Error:', error);
            });
      }

    return (
        <div className="unsubscribe-page">
            <Helmet>
                <link href="../assets//bootstrap-icons/bootstrap-icons.css" rel="stylesheet"/>
                <link href="../assets/boxicons/css/boxicons.min.css" rel="stylesheet"/>
            </Helmet>
            <NavBar />
            <section>
                <div className="container box">
                <div className="section-title mb-3 mt-3 pb-0"><h2>unsubscribe</h2></div>
                <p>Are you sure you want to unsubscribe ?</p>
                    <form action="#" id="email-form">
                        <ul className="sasw_list mb0">
                        <li className="search_area"><div className="form-group mb-3" onChange={(e)=>setMessage(e.target.value)}><textarea id="form_message" name="form_message" className="form-control " rows={5} required placeholder="Unsubscribe reason ?" defaultValue={""} /></div></li>
                        <li><div className="search_option_button"><button onClick={Handlesubmit} type="submit" className="green-btn">Unsubscribe</button></div></li>
                        </ul>
                        </form>
                </div>
            </section>
            <Footer />
            
        </div>
    )

}