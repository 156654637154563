import React, { useEffect } from 'react';
import './nav.css';

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faBars } from '@fortawesome/free-solid-svg-icons'
//mport logo from "./logo.png"

import {HashLink as Link} from 'react-router-hash-link';

export default function Nav(props){
    function jquery () {
        const script = document.createElement("script");
    
        script.src = "https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js";
        script.async = true;
    
        document.body.appendChild(script);
    }
    useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "../nav.js";
        script.async = true;
      
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        }
      }, []);
      jquery ();

      function openMobileNav(){
        document.getElementById("navbar").classList.toggle('navbar-mobile')
        document.getElementById("nav-icon").classList.toggle('bi-list')
        document.getElementById("nav-icon").classList.toggle('bi-x')

      }

    return (
        <header id="header" className="wrap d-flex align-items-center">
        <div className="container d-flex align-items-center justify-content-between">
      
        <Link to="/#"><div className="logo">
            <img src='/logo.png'/>
            <h1 className="text-light"><a><span>Prime Estate Reality</span></a></h1>
          </div></Link>
  
          <nav id="navbar" className="navbar" >
            <ul>
              <li><Link className="nav-link scrollto" to="/#hero">Home</Link></li>
              {!props.propertySection ? <li><Link className="nav-link scrollto" to="/Properties#Properties">Properties</Link></li> :<li><Link className="nav-link scrollto" to="/Properties">Properties</Link></li> }
              <li><Link className="nav-link scrollto" to="/#about">About</Link></li>
              <li><Link className="nav-link scrollto" to="/#team">Team</Link></li>
              <li><Link className="nav-link scrollto" to="/#contact">Contact</Link></li>
            </ul>
            <div className="mobile-nav-toggle-div" style={{width:"30px",height:"60px",justifyContent:"flex-end",alignItems:"center"}} onClick={openMobileNav}>
            <i className="bi bi-list mobile-nav-toggle" id="nav-icon" ></i>
            </div>
          </nav>
  
        </div>
        </header>
    )
}