import React, {useState} from "react"
import NavBar from "../../components/navbar/index"
import Footer from "../../components/footer/index"
import Properties from "../../components/properties"
import './homepage.css'
import {Helmet} from "react-helmet"
import { validate } from 'react-email-validator';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



import team1 from"./team-img/team-1.jpg"
import team2 from"./team-img/team-2.jpg"
import team3 from"./team-img/team-3.jpg"
import team4 from"./team-img/team-4.jpg"
import about from "./about.jpg"

//import {HashLink as Link} from 'react-router-hash-link';
export default function HomePage(){
  const googleMapsUrl = `//maps.google.com/maps?q=40.3381768,-6.2613077&z=15&output=embed`

  const [name,setName] = useState()
  const [message,setMessage] = useState()
  const [phone,setPhone] = useState()
  const [emailValue,setEmailValue] = useState()
  const [emailValid,setEmailValid] = useState(true)
  const postUrl ="https://api.primestaterealty.omarwasfi.com/ClientRequest/AddClientRequest"

  const notify = () => toast.success('Success', {
    className: 'toast-message'
  });
  const notValidNotify = () => toast.error('Not a valid email', {
    className: ''
  });

  function Handlesubmit (e){
    e.preventDefault()
    const form = document.getElementById('email-form');
    let data = {
      name: name,
      email: emailValue,
      phoneNumber: phone,
      message: message,
      comingFromProperty: false,
      savedTheProperty: false,
      propertyId: ""
    }
    //console.log(data)
    if (validate(emailValue)){ 
      form.reset()
      notify()   
      //console.log("submit")
      fetch(postUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json-patch+json',
          'accept': 'text/plain'
        },
        body: JSON.stringify(data)
      })
        //.then(response => response.json())
        .then(data => {
          //console.log('Success:', data);
        })
        .catch((error) => {
          //console.error('Error:', error);
        });
    }else{
      setEmailValid(false)
      notValidNotify()
    }
  }


    return (
        <div >
          <Helmet>
          <link href="./assets//bootstrap-icons/bootstrap-icons.css" rel="stylesheet"/>
          <link href="./assets/boxicons/css/boxicons.min.css" rel="stylesheet"/>
          </Helmet>

            <section id="hero" className="wrap">
                <div className="hero-container" data-aos="fade-up">
                    <h1>Start a new experience with <span>Prime Estate Reality</span></h1>
                    <h2>Discover Your Next Property in USA with PrimestateRealty - Your Premier Destination for Homes and Investment Opportunities.</h2>
                    <a href="#Properties" className="btn-get-started scrollto">Get Started</a>
                </div>
                
            </section>
            <NavBar/>
        <div className="wrap">
            <Properties number={3}/>

            <section id="about" className="about section-bg ">
              <div className="container" data-aos="fade-up">
                <div className="section-title">
                  <h2>About</h2>
                  <h3>Find Out More <span>About Us</span></h3>
                  <p></p>
                </div>
                <div className="row">
                  <div className="col-lg-6" data-aos="fade-right" data-aos-delay={100}>
                    <img src={about} className="img-fluid" alt="" />
                  </div>
                  <div className="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay={100}>
                    <h3>PrimestateRealty is your premier destination for finding your next property.</h3>
                    <p className="fst-italic">
                    We offer a wide variety of homes for sale, including townhomes, condos, and new homes. Our real estate listings are constantly updated with new properties, ensuring that you always have the latest options available to you.
                    </p>
                    <ul>
                      <li>
                        <i className="bx bx-phone-call" />
                        <div>
                          <h5>Let Our Real Estate Agents Help You Find Your Dream Property</h5>
                          <p>Our experienced real estate agents are here to guide you through the process of finding and buying your next property. We understand that every buyer has unique needs and preferences, and we're committed to matching you with the perfect property that fits your budget and lifestyle.</p>
                        </div>
                      </li>
                      <li>
                        <i className="bx bx-wallet" />
                        <div>
                          <h5>Secure Your Financial Future with PrimestateRealty</h5>
                          <p>At PrimestateRealty, we also understand that investing in real estate can be a smart financial move that can lead to long-term wealth. That's why we offer a range of investment opportunities, including properties with potential for appreciation and high rental income.</p>
                        </div>
                      </li>
                    </ul>
                    <p>
                      Whether you're a first-time homebuyer or an experienced investor, we're here to make the process as smooth and stress-free as possible. With our easy-to-use home search tool and expert guidance, you're sure to find the home of your dreams.
                    </p>
                    <p>
                      Contact PrimestateRealty today to learn more about our wide selection of homes for sale and investment opportunities. We're here to help you discover your next property with ease.                    </p>
                  </div>
                </div>
              </div>
            </section>{/* End About Section */}
              
              
            <section id="team" className="team section-bg">
              <div className="container" data-aos="fade-up">
                <div className="section-title">
                  <h2>Team</h2>
                  <h3>Our Hardworking <span>Team</span></h3>
                  <p>our team is committed to providing exceptional service to ensure a smooth and stress-free experience.</p>
                </div>
                <div className="row">
                  <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={100}>
                    <div className="member">
                      <div className="member-img">
                        <img src={team1} className="img-fluid" alt="" />
                        <div className="social">
                          <a href><i className="bi bi-twitter" /></a>
                          <a href><i className="bi bi-facebook" /></a>
                          <a href><i className="bi bi-instagram" /></a>
                          <a href><i className="bi bi-linkedin" /></a>
                        </div>
                      </div>
                      <div className="member-info">
                        <h4>Walter White</h4>
                        <span>Chief Executive Officer</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={200}>
                    <div className="member">
                      <div className="member-img">
                        <img src={team2} className="img-fluid" alt="" />
                        <div className="social">
                          <a href><i className="bi bi-twitter" /></a>
                          <a href><i className="bi bi-facebook" /></a>
                          <a href><i className="bi bi-instagram" /></a>
                          <a href><i className="bi bi-linkedin" /></a>
                        </div>
                      </div>
                      <div className="member-info">
                        <h4>Sarah Jhonson</h4>
                        <span>Product Manager</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={300}>
                    <div className="member">
                      <div className="member-img">
                        <img src={team3} className="img-fluid" alt="" />
                        <div className="social">
                          <a href><i className="bi bi-twitter" /></a>
                          <a href><i className="bi bi-facebook" /></a>
                          <a href><i className="bi bi-instagram" /></a>
                          <a href><i className="bi bi-linkedin" /></a>
                        </div>
                      </div>
                      <div className="member-info">
                        <h4>William Anderson</h4>
                        <span>CTO</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={400}>
                    <div className="member">
                      <div className="member-img">
                        <img src={team4} className="img-fluid" alt="" />
                        <div className="social">
                          <a href><i className="bi bi-twitter" /></a>
                          <a href><i className="bi bi-facebook" /></a>
                          <a href><i className="bi bi-instagram" /></a>
                          <a href><i className="bi bi-linkedin" /></a>
                        </div>
                      </div>
                      <div className="member-info">
                        <h4>Amanda Jepson</h4>
                        <span>Accountant</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="contact" className="contact">
              <div className="container" data-aos="fade-up">
                <div className="section-title">
                  <h2>Contact</h2>
                  <h3><span>Contact Us</span></h3>
                  <p>Contact us now to learn more about our expertise and how we can help you find the perfect property to meet your needs and preferences.</p>
                </div>
                <div className="row" data-aos="fade-up" data-aos-delay={100}>
                  <div className="col-lg-6">
                    <div className="info-box mb-4">
                      <i className="bx bx-map" />
                      <h3>Our Address</h3>
                      <p>A108 Adam Street, New York, NY 535022</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="info-box  mb-4">
                      <i className="bx bx-envelope" />
                      <h3>Email Us</h3>
                      <p>contact@example.com</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="info-box  mb-4">
                      <i className="bx bx-phone-call" />
                      <h3>Call Us</h3>
                      <p>+1 5589 55488 55</p>
                    </div>
                  </div>
                </div>
                <div className="row" data-aos="fade-up" data-aos-delay={100}>
                  <div className="col-lg-6 ">
                    <iframe className="mb-4 mb-lg-0" src={googleMapsUrl}  frameBorder={0} style={{border: 0, width: '100%', height: '384px'}} allowFullScreen />
                  </div>
                  <div className="col-lg-6">
                    <form action="forms/contact.php" method="post" role="form" id="email-form" className="php-email-form">
                      <div className="row">
                        <div className="col form-group" onChange={(e)=>setName(e.target.value)}>
                          <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                        </div>
                        <div className="col form-group" onChange={(e)=>setEmailValue(e.target.value)}>
                          <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                        </div>
                      </div>
                      <div className="form-group" onChange={(e)=>setPhone(e.target.value)}>
                        <input type="text" className="form-control" name="PhoneNumber" id="PhoneNumber" placeholder="Phone Number" required />
                      </div>
                      <div className="form-group" onChange={(e)=>setMessage(e.target.value)}>
                        <textarea className="form-control" name="message" rows={5} placeholder="Message" required defaultValue={""} />
                      </div>
                      <div className="my-3">
                        <div className="loading">Loading</div>
                        <div className="error-message" />
                        <div className="sent-message">Your message has been sent. Thank you!</div>
                      </div>
                      <div className="text-center"><button onClick={Handlesubmit} type="submit">Send Message</button></div>
                    </form>
                  </div>
                </div>
              </div>
            </section>{/* End Contact Section */}
            <Footer/>
            <ToastContainer />
        </div>
        
        </div>
    )

}
