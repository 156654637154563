import React, { useEffect, useState } from "react"
import MyGallery from "../../components/gallery/"
import {Helmet} from "react-helmet"

import NavBar from "../../components/navbar/index"
import Footer from "../../components/footer/index"
import ReactStars from "react-rating-stars-component";
import "./property.css"
import { validate } from 'react-email-validator';
import EmailModal from "./Modal/index"

import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function Property(props){
    const url = "https://api.primestaterealty.omarwasfi.com/Property/GetPropertyById?propertyId="
    const propertyId =useParams().id;
    const propertyRateUrl ="https://api.primestaterealty.omarwasfi.com/PropertyRate/AddPropertyRate"
    
    const ratingChanged = (newRating) => {
      let data = {
          propertyId: propertyId,
          rate: newRating
      }
      fetch(propertyRateUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json-patch+json',
          'accept': 'text/plain'
        },
        body: JSON.stringify(data)
      })
        //.then(response => response.json())
        .then(newRating => {
          //console.log('Success:', data);
        })
        .catch((error) => {
          //console.error('Error:', error);
        });
    };

    const [items, setitems] = useState([])
    const [loaded, setloaded] = useState(false)
    const [modalShow, setModalShow] = useState(false);
    
    const fetchUserData = () => {
        fetch(url+propertyId)
          .then(response => {
            return response.json()
          })
          .then(data => {
            setitems(data)
            setloaded(true)
          })
      }
      useEffect(() => {
        fetchUserData()
      }, [])


      let day = new Date(items.PublishedOnDateTime).getDate();
      let month = new Date(items.PublishedOnDateTime).getMonth()+1;
      let year =new Date(items.PublishedOnDateTime).getFullYear();
      let dateFormat = `${month}-${day}-${year}`

      const [name,setName] = useState()
      const [message,setMessage] = useState()
      const [phone,setPhone] = useState()
      const [savedTheProperty,setSavedTheProperty] = useState(true)
      const [emailValue,setEmailValue] = useState()
      const postUrl ="https://api.primestaterealty.omarwasfi.com/ClientRequest/AddClientRequest"
      const [emailValid,setEmailValid] = useState(true)
      
      
      const notify = () => toast.success('Success', {
        className: 'toast-message'
      });
      const notValidNotify = () => toast.error('Not a valid email', {
        className: ''
      });
      function Handlesubmit (e){
        e.preventDefault()
        const form = document.getElementById('email-form');
        let data = {
          name: name,
          email: emailValue,
          phoneNumber: phone,
          message: message,
          comingFromProperty: true,
          savedTheProperty: savedTheProperty,
          propertyId: propertyId
        }
        //console.log(data)
        if (validate(emailValue)){    
          form.reset()
          //console.log("submit")
          notify()
          fetch(postUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json-patch+json',
              'accept': 'text/plain'
            },
            body: JSON.stringify(data)
          })
            //.then(response => response.json())
            .then(data => {
              //console.log('Success:', data);
            })
            .catch((error) => {
              //console.error('Error:', error);
            });
        }else{
          setEmailValid(false)
          notValidNotify()
        }
      }

      
      if (loaded){
      //console.log(items)

      const googleMapsUrl = `//maps.google.com/maps?q=${items.Location.Geolocation.Latitude},${items.Location.Geolocation.Longitude}&z=15&output=embed`
    return (
        <div className="property-page">
          <Helmet>
          <link href="../assets//bootstrap-icons/bootstrap-icons.css" rel="stylesheet"/>
          <link href="../assets/boxicons/css/boxicons.min.css" rel="stylesheet"/>
          </Helmet>
          <NavBar propertySection={false}/>
          <section>
          <div className="container">
            <div className="row layout">
                  <div className="col-sm-7 col-lg-8 property-left">
                      <MyGallery photos={items.Pictures} 
                          thumbnail={items.Thumbnail} 
                          />
                      <div>
                      <div className="property-info">
                        <div>
                            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",paddingBottom:"5px"}}>
                            <ReactStars
                              count={5}
                              onChange={ratingChanged}
                              size={26}
                              emptyIcon={<i class="bi bi-star"></i>}
                              fullIcon={<i class="bi bi-star"></i>}
                              activeColor="#d2a04b"
                            />
                              <div className="save-to-email" onClick={() => setModalShow(true)}><i class="bi bi-heart"></i>Save to Email</div>
                            </div>
                            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                              <div className="location"><i class="fa-solid fa-location-dot" style={{marginRight:"2px"}}></i>{items.Location.State.Abbreviation}-{items.Location.State.Name}, {items.Location.County.Name}</div>
                              {items.PriceAvaiable ? <div className="price"><a>${items.Price}</a></div> : <div className="price Bid"><a href={items.bidURL}>Bid Now</a></div>}
                            </div>
                  
                            

                        </div>
                        <hr style={{margin:"15px 0px"}}/>
                        <div className="property-description">
                                <h5 className="mb30">Description</h5>
                                {items.LegalDescription &&
                                <p className="mb25">{items.LegalDescription}</p>
                                }
                        </div>
                        <hr/>
                        <div className="property details additional_details">
                          
                                <div className="row">
                                  <div className="col-lg-12">
                                    <h5 className="mb15">Property Details</h5>
                                  </div>
                                  <div className="col-md-6 col-lg-6 col-xl-4">
                                    <ul className="list-inline-item">
                                      {items.ParcelId &&
                                      <li>
                                        <p>Parcel Code : <span>{items.ParcelId}</span></p>
                                      </li>
                                      }
                                      <li>
                                        <p>Price : <span>${items.Price}</span></p>
                                      </li>
                                      <li>
                                        <p>Property Size : <span>{items.AreaSqFt} ft²<i class="bi bi-rulers"></i></span></p>
                                      </li>
                                      <li>
                                        <p>Year Built : <span>{dateFormat}</span></p>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="col-md-6 col-lg-6 col-xl-4">
                                    <ul className="list-inline-item">
                                      {items.Beds && 
                                      <li><p>Beds : <span>{items.Beds}<i class="fa-solid fa-bed"></i></span></p></li> 
                                      }
                                      {items.Bathrooms && 
                                      <li>
                                        <p>Bathrooms : <span>{items.Bathrooms}<i class="fa-solid fa-shower"></i></span></p>
                                      </li>
                                      }
                                      {items.Floors && 
                                      <li>
                                        <p>Floors : <span>{items.Floors}</span></p>
                                      </li>
                                      }
                                      {items.Garages && 
                                      <li>
                                        <p>Garage : <span>{items.Garages}</span></p>
                                      </li>
                                      }
                                    </ul>
                                  </div>
                                  <div className="col-md-6 col-lg-6 col-xl-4">
                                    <ul className="list-inline-item">
                                      <li>
                                        <p>Property Type : <span>{items.PropertyTypeName}</span></p>
                                      </li>
                                      <li>
                                        <p>Property SubType : <span>{items.PropertySubTypeName}</span></p>
                                      </li>
                                    </ul>
                                    
                                  </div>
                                  <p className="wire-funds">DO NOT wire funds (e.g. Western Union), or buy/rent sight unseen.</p>
                                  <iframe className="mb-4 mb-lg-0 mobile-map" src={googleMapsUrl} frameBorder={0} style={{border: 0, width: '100%', height: '270px'}} allowFullScreen />


                                </div>
                        </div>
                      </div>


                    </div>
                </div>
                
                <div className="col-sm-5 col-lg-4 property-right">
                  <div className="property-sidebar">
                  
                  <div className="section-title mb-3 pb-0"><h2>Contact</h2></div>
                  <form action="#" id="email-form">
                    <ul className="sasw_list mb0">
                      <li className="search_area"><div className="form-group mb-3" onChange={(e)=>setName(e.target.value)}><input type="text" className="form-control" placeholder="Your Name" required /></div></li>
                      <li className="search_area"><div className="form-group mb-3" onChange={(e)=>setPhone(e.target.value)}><input type="number" className="form-control" placeholder="Phone" required /></div></li> 
                      <li className="search_area"><div className="form-group mb-3" onChange={(e)=>setEmailValue(e.target.value)}><input type="email" className="form-control" placeholder="Email" required /></div></li> 
                      <li className="search_area"><div className="form-group mb-3" onChange={(e)=>setMessage(e.target.value)}><textarea id="form_message" name="form_message" className="form-control " rows={5} required placeholder="Your Message" defaultValue={""} /></div></li>
                      <li className="">
                      <div className="form-check mb-3">
                        <input className="form-check-input" type="checkbox" defaultValue id="flexCheckChecked" defaultChecked />
                        <label className="form-check-label" htmlFor="flexCheckChecked" onClick={(e)=>setSavedTheProperty(!savedTheProperty)}>
                          Save property to my Email
                        </label>
                      </div></li>
                      <li><div className="search_option_button"><button onClick={Handlesubmit} type="submit" className="green-btn">Send Email</button></div></li>
                    </ul>
                    </form>
                    <iframe className="mb-4 mb-lg-0 desktop-map" src={googleMapsUrl} frameBorder={0} style={{border: 0, width: '100%', height: '270px'}} allowFullScreen />

                  </div>
                </div>
            </div>
          </div>
          </section>
          <Footer propertySection={false}/>
          <EmailModal 
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  propertyId={propertyId}
          />
        <ToastContainer />
    </div>
    )

    }
    else {
        return (
          <div >
            <div style={{height:"80vh",display:"flex",justifyContent:"center",alignItems:"center"}}><div class="loader"></div></div>
          </div>
        )
    }

    }
    
export default Property;    

