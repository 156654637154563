import React from "react";

import Carousel from "./carousel/index.js";
import {HashLink as Link} from 'react-router-hash-link';

export default function Card(props){

    let day = new Date(props.date).getDate();
    let month = new Date(props.date).getMonth()+1;
    let year =new Date(props.date).getFullYear();
    let dateFormat = `${month}-${day}-${year}`

    return (
        
        <div className="propery-card">
            <Link to={"/view/"+props.id} state={props.id}>
            <div className="card-img-div">
                <Carousel 
                pictures={props.pictures} 
                thumbnail={props.thumbnail}
                />
            </div>
                <div className="card-info-div">
                    <div style={{display:"flex",justifyContent:"space-between"}}>
                        <div className="location"><i class="fa-solid fa-location-dot" style={{marginRight:"2px"}}></i>{props.location.State.Abbreviation}-{props.location.State.Name}, {props.location.County.Name}</div>
                        <div className="date">{dateFormat}</div>
                    </div>
                    <div style={{display:"flex",justifyContent:"space-between"}}>
                        <div className="type">{props.type}, {props.subType}</div>
                        <div className="area">{props.area} ft²</div>
                    </div>
                    {props.priceAvaiable ? <div className="price"><a href="">${props.price}</a></div> : <div className="price"><a href="">Bid Now</a></div>} 
                    
                </div>
            </Link>
        </div>
    )}