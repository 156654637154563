import React from "react";
import NavBar from "../../components/navbar/index"
import Properties from "../../components/properties"
import {Helmet} from "react-helmet"
import "./propertiesPage.css"
import Footer from "../../components/footer/index"

export default function propertiesPage(){

    return (
        <div className="properties-page">
            <Helmet>
          <link href="./assets//bootstrap-icons/bootstrap-icons.css" rel="stylesheet"/>
          <link href="./assets/boxicons/css/boxicons.min.css" rel="stylesheet"/>
          </Helmet>
            <NavBar/>
            <div className="wrap">
                <Properties/>
                <Footer/>
            </div>
            
        </div>
    )

}
