import * as React from "react";
import Lightbox from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
//import Carousel from "../properties/propertyCard/carousel"
import Carousel from 'react-bootstrap/Carousel';




const url = "https://api.primestaterealty.omarwasfi.com/"


export default function App(props) {

  const [open, setOpen] = React.useState(false);
    
  const zoomRef = React.useRef(null); 
   
  const [index, setIndex] = React.useState();
  const [controls, setControls] = React.useState(false);
   let photos =[]
   function openLightbox () {setOpen(true)}
   
   photos =props.photos.map(function(photo,index){
        return(
            {
                
              src: url+photo.FullPath1000px,
              alt: "property-image",
              width: 3840,
              height: 2560,
  
            }
            )
          
           
    })
    photos.unshift( {      
      src: url+props.thumbnail.FullPath1000px,
      alt: "property-image",
      width: 3840,
      height: 2560,
  
    })
  return (
    <>
      <div className="card-img-div"  style={{position:"relative",width:"40",margin:"0px auto"}} 
      onMouseEnter={function (){setControls(true)}}
      onMouseLeave={function (){setControls(false)}}
      onTouchEnd={function (){setControls(!controls)}}
      >
              <Carousel fade interval={null}  className="property-carousel" 
              onSlide={function (e){setIndex(e)}}
              controls={true}
              indicators={true}
              disableMouseEvents
              >
                
                  <Carousel.Item>
                    <div onMouseMove={event => event.stopPropagation()} onClick={openLightbox} style={{width:"70%",height:"100%",position:"absolute",right:"15%",zIndex:"3"}}></div>
                    <img
                      className="d-block w-100"
                      src={url+props.thumbnail.FullPath1000px}
                      alt="First slide"
                    />
          
                  </Carousel.Item>
              {
                props.photos.map(function(picture){
                  return(
                  <Carousel.Item>
                    <div onMouseMove={event => event.stopPropagation()} onClick={openLightbox} style={{width:"70%",height:"100%",position:"absolute",right:"15%",zIndex:"3"}}></div>
                    <img
                      className="d-block w-100"
                      src={url+picture.FullPath1000px}
                      alt="First slide"
                    />
          
                  </Carousel.Item>
                  )
                })
              }


              </Carousel>
            
      </div>

      <Lightbox
        index={index}
        zoom={{ ref: zoomRef ,
            zoomInMultiplier:1.5,
            scrollToZoom:true,
            maxZoomPixelRatio:2
        }}
        on={{
          click: () => zoomRef.current?.zoomIn()
        }}

        plugins={[Counter,Zoom]}
        counter={{ style: { top: "unset", bottom: 0 } }}
        open={open}
        close={() => setOpen(false)}
        slides={photos}
      />
    </>
  );
}