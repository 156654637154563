import React, {useState} from "react"
import { validate } from 'react-email-validator';
import "./footer.css"
import {HashLink as Link} from 'react-router-hash-link';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function Footer (props){

  const [emailValue,setEmailValue] = useState()
  const [emailValid,setEmailValid] = useState(true)
  const postUrl ="https://api.primestaterealty.omarwasfi.com/ClientRequest/AddClientRequest"

  const notify = () => toast.success('Success', {
    className: 'toast-message'
  });
  const notValidNotify = () => toast.error('Not a valid email', {
    className: ''
  });

  function Handlesubmit (e){
    e.preventDefault()
    const form = document.getElementById('footer-email-form');
    let data = {
      name: "",
      email: emailValue,
      phoneNumber: "",
      message: "",
      comingFromProperty: false,
      savedTheProperty: false,
      propertyId: ""
    }
    //console.log(data)
    if (validate(emailValue)){    
      form.reset()
      notify()
      //console.log("submit")
      fetch(postUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json-patch+json',
          'accept': 'text/plain'
        },
        body: JSON.stringify(data)
      })
        //.then(response => response.json())
        .then(data => {
          //console.log('Success:', data);
        })
        .catch((error) => {
          //console.error('Error:', error);
        });
    }else{
      setEmailValid(false)
      notValidNotify()
    }
  }
    return (
        <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 footer-contact">
                <h3>PRIME ESTATE REALITY<span></span></h3>
                <p>
                  A108 Adam Street <br />
                  New York, NY 535022<br />
                  United States <br /><br />
                  <strong>Phone:</strong> +1 5589 55488 55<br />
                  <strong>Email:</strong> info@example.com<br />
                </p>
              </div>
              <div className="col-lg-2 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li><i className="bx bx-chevron-right" /> <Link to="/#hero">Home</Link></li>
                  {!props.propertySection ?<li><i className="bx bx-chevron-right" /> <Link to="/Properties#Properties">Properties</Link></li>
                   : <li><i className="bx bx-chevron-right" /> <Link to="#Properties">Properties</Link></li> }
                  <li><i className="bx bx-chevron-right" /> <Link to="/#about">About us</Link></li>
                  <li><i className="bx bx-chevron-right" /> <Link to="/#team">Team</Link></li>
                  <li><i className="bx bx-chevron-right" /> <Link to="/#contact">Contact</Link></li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Our Social Networks</h4>
                <p>Connect with us on social network.</p>
                <div className="social-links mt-3">
                  <a href="#" className="twitter"><i className="bx bxl-twitter" /></a>
                  <a href="#" className="facebook"><i className="bx bxl-facebook" /></a>
                  <a href="#" className="instagram"><i className="bx bxl-instagram" /></a>
                  <a href="#" className="google-plus"><i className="bx bxl-skype" /></a>
                  <a href="#" className="linkedin"><i className="bx bxl-linkedin" /></a>
                </div>
              </div>
              
              <div className="col-lg-4 col-md-6 footer-links footer-newsletter">
                  <h4>Join Our Newsletter</h4>
                  <p>We will provide you with the best properties and the latest.</p>
                    <form id="footer-email-form">
                    <input onChange={(e)=>setEmailValue(e.target.value)} type="email" name="email" placeholder="Enter your email"/>
                    <input onClick={Handlesubmit} type="submit" value="Subscribe"  />
                  </form>
              </div>
            </div>
          </div>
        </div>
        <div className="container py-4">
          <div className="copyright">
            © Copyright <strong><span>PRIME ESTATE REALITY</span></strong>. All Rights Reserved
          </div>
          {
          //<div className="credits">
            //Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
          //</div>
        }
        </div>
        <ToastContainer />
        </footer>
    )
}